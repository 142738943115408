import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { PrivateRoute } from "./shared/PrivateRoute.js";
import { BiPrivateRoute } from "./shared/BiPrivateRoute.js";
import { ErrorBoundary } from "../widgets/ErrorBoundary/ErrorBoundary.js";
import Dashboard from "../Components/Dashboard/Dashboard.js";
import ListSMS from "../Components/Inbox/SMS/ListSMS.js";
import CreateSMS from "../Components/Inbox/SMS/CreateSMS.js";
import List from "../Components/Inbox/List/List.js";
import SMSNotifications from "../Components/Dashboard/SMSNotifications.js";
import SMSNotificationsPopup from "../Components/Dashboard/SMSNotificationsPopup.js";
import Notifications from "../Components/Dashboard/Notifications.js";
import SmsCampaign from "../Components/Inbox/SMSCampaign/SmsCampaign.js";
import SmsTemplate from "../Components/Inbox/SMSTemplate/SmsTemplate.js";
import Clients from "../Components/Clients/Clients.js";
import ClientProfile from "../Components/Clients/ClientProfile/ClientProfile.js";
import CreateEditClients from "../Components/Clients/CreateEditClients.js";
import ClientWallet from "../Components/Clients/ClientWallet.js";
import CreateEditClientDocuments from "../Components/Clients/Documents/CreateEditClientDocuments.js";
import ClientDocuments from "../Components/Clients/Documents/ClientDocuments.js";
import ClientFax from "../Components/Clients/Efax/ClientFax.js";
import Leads from "../Components/Leads/Leads.js";
import ExportProcedures from "../Components/ExportProcedures/ExportProcedures.js";
import ViewFilledSurvey from "../Components/ViewFilledSurvey/ViewFilledSurvey.js";
import BulkImport from "../Components/Clients/BulkImport.js";
import AfterPhotos from "../Components/Clients/AfterPhotos.js";
import BAVideos from "../Components/Clients/BAVideos.js";
import AppointmentQuestionnaires from "../Components/Clients/Questionnaires/AppointmentQuestionnaires.js";
import ScanComponent from "../Components/Clients/MergeClients/ScanPage.js";
import MergeProfile from "../Components/Clients/MergeClients/MergeProfilesMain.js";
import DocumentQuestionnaire from "../Components/Clients/Questionnaires/CosmeticQuestionnaires.js";
import DocumentHealthQuestionnaires from "../Components/Clients/Questionnaires/HealthQuestionnaires.js";
import DocumentConsents from "../Components/Clients/Consents/DocumentConsents.js";
import HealthInvoiceCreation from "../Components/Clients/Invoice/InvoiceCreateEdit.js";
import ServiceCategory from "../Components/Appointment/Services/ServiceCategory.js";
import CreateEditServiceCategory from "../Components/Appointment/Services/CreateEditServiceCategory.js";
import Services from "../Components/Appointment/Services/Services.js";
import CreateEditServices from "../Components/Appointment/Services/CreateEditServices.js";
import NewCalendar from "../Components/Appointment/Calendar/NewCalendar.js";
import ProviderWeekCalendar from "../Components/Appointment/Calendar/ProviderWeekCalendar.js";
import CreateEditEquipmentSchedule from "../Components/Appointment/EquipmentSchedule/CreateEditEquipmentSchedule.js";
import ResourceSchedule from "../Components/Appointment/ResourceSchedule/ResourceSchedule.js";
import CreateEditResourceSchedule from "../Components/Appointment/ResourceSchedule/CreateEditResourceSchedule.js";
import ServicesPackages from "../Components/Appointment/ServicesPackages/ServicesPackages.js";
import CreateEditServicesPackages from "../Components/Appointment/ServicesPackages/CreateEditServicesPackages.js";
import ProviderSchedule from "../Components/Appointment/ProviderSchedule/ProviderSchedule.js";
import ProviderScheduleView from "../Components/Appointment/ProviderSchedule/ProviderScheduleView.js";
import ProviderScheduleDelete from "../Components/Appointment/ProviderSchedule/ProviderScheduleDelete.js";
import BookingHistory from "../Components/Appointment/BookingHistory/BookingHistory.js";
import AppointmentReports from "../Components/Appointment/Reports/Reports.js";
import AppointmentConfig from "../Components/Appointment/AppointmentConfig.js";
import OtherResources from "../Components/Appointment/OtherResources.js";
import AppointmentCommunication from "../Components/Appointment/AppointmentCommunication.js";
import BookingPortal from "../Components/Appointment/BookingPortal.js";
import ServicesMain from "../Components/Appointment/ServicesMain.js";
import OtherSettings from "../Components/Appointment/OtherSettings.js";
import Verticals from "../Components/Appointment/Verticals/Verticals.js";
import Waitlist from "../Components/Appointment/Waitlist/List.js";
import InvoiceDisclaimerText from "../Components/Settings/Pos/InvoiceDisclaimer/InvoiceDisclaimerText.js";
import SalesInvoices from "../Components/Sales/Invoices/Invoices.js";
import SalesInvoicesPopups from "../Components/Sales/Invoices/SalesInvoicesPopups.js";
import CashDrawer from "../Components/Sales/CashDrawer/CashDrawer.js";
import DashboardRoles from "../Components/Settings/ReportSettings/DashboardSettings/DashboardRoles.js";
import FrontdeskCheckout from "../Components/Sales/FrontdeskCheckout/FrontdeskCheckout.js";
import InventoryProductsActive from "../Components/Inventory/Products/InventoryProductActive.js";
import Pricelist from "../Components/Inventory/Pricelist/List";
import InventoryProductsInactive from "../Components/Inventory/Products/InventoryProductInactive.js";
import EditInventory from "../Components/Inventory/Products/EditInventory.js";
import ProductCategories from "../Components/Inventory/ProductCategories.js";
import CreateEditCategories from "../Components/Inventory/CreateEditCategories.js";
import InventoryProductsCategory from "../Components/Inventory/InventoryProductCategory.js";
import TreatmentPlanTemplate from "../Components/Inventory/TreatmentPlanTemplate.js";
import CreateTreatmentPlanTemplate from "../Components/Inventory/CreateTreatmentPlanTemplate.js";
import InventoryReconciliation from "../Components/Inventory/InventoryReconciliation.js";
import Suppliers from "../Components/Inventory/orders/Suppliers.js";
import PurchaseOrder from "../Components/Inventory/orders/PurchaseOrder.js";
import ViewEditSupplier from "../Components/Inventory/orders/ViewEditSupplier.js";
import ImportSupplier from "../Components/Inventory/orders/ImportSupplier.js";
import PurchaseOrderDetails from "../Components/Inventory/orders/PurchaseOrderDetails.js";
import CreatePurchaseOrder from "../Components/Inventory/orders/CreatePurchaseOrder.js";
import StockTransfer from "../Components/Inventory/StockTransfer/StockTransfer.js";
import CreateEditStockTransfer from "../Components/Inventory/StockTransfer/CreateEditStockTransfer.js";
import StockTransferDetails from "../Components/Inventory/StockTransfer/StockTransferDetails.js";
import CreateEditChartingPackages from "../Components/Inventory/Products/CreateEditChartingPackages.js";
import Reports from "../Components/Reports/Reports.js";
import CreateReports from "../Components/Reports/CreateReports.js";
import ReportEdit from "../Components/Reports/ReportEdit.js";
import AllSurveys from "../Components/Survey/AllSurveys.js";
import SurveyList from "../Components/Survey/SurveyList.js";
import CreateEditSurveyTemplate from "../Components/Survey/CreateEditSurveyTemplate.js";
import ViewAllSurveys from "../Components/Survey/ViewAllSurveys.js";
import SurveyData from "../Components/Survey/SurveyData.js";
import UserActivity from "../Components/UserActivity/UserActivity.js";
import ViewComparison from "../Components/UserActivity/ViewComparison.js";
import Araccount from "../Components/Settings/Account/Araccount.js";
import Payroll from "../Components/Settings/Account/Payroll.js";
import ArTermsOfUse from "../Components/Settings/Account/ArTermsOfUse.js";
import HipaaTermsOfUse from "../Components/Settings/Account/HipaaTermsOfUse.js";
import Profile from "../Components/Settings/Account/Profile.js";
import GoogleCalendarReturnUrl from "../Components/Settings/Account/GoogleCalendarReturnUrl.js";
import SettingsSurveyList from "../Components/Settings/Survey/SurveyList.js";
import SettingsCreateEditSurveyTemplate from "../Components/Settings/Survey/CreateEditSurveyTemplate.js";
import Questionnaires from "../Components/Settings/ManageClinics/Questionnaires/Questionnaires.js";
import CreateEditQuestionnaire from "../Components/Settings/ManageClinics/Questionnaires/CreateQuestionnaries.js";
import Clinics from "../Components/Settings/ManageClinics/Clinics/Clinics.js";
import CreateEditClinics from "../Components/Settings/ManageClinics/Clinics/CreateEditClinics.js";
import ProcedureTemplates from "../Components/Settings/ManageClinics/ProcedureTemplates/Procedure-templates.js";
import PostTreatmentEmail from "../Components/Settings/ManageClinics/PostTreatmentEmail/PostTreatmentEmail.js";
import PostTreatmentInstructions from "../Components/Settings/ManageClinics/PostTreatmentInstructions/PostTreatmentInstructions.js";
import PreTreatmentInstructions from "../Components/Settings/ManageClinics/PreTreatmentInstructions/PreTreatmentInstructions.js";
import PreTreatmentEmail from "../Components/Settings/ManageClinics/PreTreatmentEmail/PreTreatmentEmail.js";
import Consents from "../Components/Settings/ManageClinics/Consents/Consents.js";
import CreateEditConsents from "../Components/Settings/ManageClinics/Consents/CreateEditConsents.js";
import CreateEditPostInstructions from "../Components/Settings/ManageClinics/PostTreatmentInstructions/CreateEditPostInstructions.js";
import CreateEditPreInstructions from "../Components/Settings/ManageClinics/PreTreatmentInstructions/CreateEditPreInstructions.js";
import CreateEditTemplate from "../Components/Settings/ManageClinics/ProcedureTemplates/CreateEditTemplate.js";
import TreatmentPlanTemplates from "../Components/Settings/ManageClinics/TreatmentPlan/TreatmentPlanTemplates.js";
import CreateEditTreatmentPlanTemp from "../Components/Settings/ManageClinics/TreatmentPlan/CreateEditTreatmentPlan.js";
import MembershipAgreement from "../Components/Settings/ManageClinics/MembershipAgreement/MembershipAgreement.js";
import CreateEditMembershipAgreement from "../Components/Settings/ManageClinics/MembershipAgreement/CreateEditMembershipAgreement.js";
import AppointmentEmailsSMS from "../Components/Settings/Appointments/AppointmentsEmailAndSMS/AppointmentEmailsSMS.js";
import AppointmentReminder from "../Components/Settings/Appointments/PatientAppointmentReminders/AppointmentReminder.js";
import CreateEditReminder from "../Components/Settings/Appointments/PatientAppointmentReminders/CreateEditReminder.js";
import ConfigureURL from "../Components/Settings/Appointments/ConfigureURL/ConfigureURL.js";
import CancellationPolicy from "../Components/Settings/Appointments/CancellationPolicy/CancellationPolicy.js";
import PatientPortal from "../Components/Settings/Appointments/PatientPortal/PatientPortal.js";
import SurveySettings from "../Components/Settings/Appointments/SurveySettings/SurveySettings.js";
import MembershipWallet from "../Components/Settings/MembershipWallet/MembershipWallet.js";
import Users from "../Components/Settings/Teammates/Users/Users.js";
import UserRoles from "../Components/Settings/Teammates/UserRoles/UserRoles.js";
import UserPrivilege from "../Components/Settings/Teammates/UserRoles/UserPrivilege.js";
import CreateEditUser from "../Components/Settings/Teammates/Users/CreateEditUser.js";
import RecentlyDeleted from "../Components/Settings/RecentlyDeleted/RecentlyDeleted.js";
import Paperwork from "../Components/Settings/RecentlyDeleted/Paperwork/Paperwork.js";
import Invoices from "../Components/Settings/YourBilling/Invoices/Invoices.js";
import Subscription from "../Components/Settings/YourBilling/Subscription/Subscription.js";
import ResourceUsage from "../Components/Settings/YourBilling/Subscription/ResourceUsage.js";
import SubscriptionAccelerator from "../Components/Settings/YourBilling/Subscription/SubscriptionAccelerator.js";
import PosKlarna from "../Components/Settings/Pos/PosKlarna/PosKlarna.js";
import PosDashboard from "../Components/Settings/Pos/PosDashboard/PosDashboard.js";
import PosDashboardVerification from "../Components/Settings/Pos/PosDashboard/PosDashboardVerification.js";
import PosPayments from "../Components/Settings/Pos/Payments/PosPayments.js";
import PosPayouts from "../Components/Settings/Pos/Payouts/PosPayouts.js";
import PosPayoutsView from "../Components/Settings/Pos/Payouts/PosPayoutsView.js";
import Pos from "../Components/Settings/Pos/Pos.js";
import OnboardingClearent from "../Components/Settings/Pos/OnboardingClearent.js";
import PosSetup from "../Components/Settings/Pos/PosSetup.js";
import PosCardReader from "../Components/Settings/Pos/PosCardReader.js";
import PosPaymentSettings from "../Components/Settings/Pos/PaymentSettings/PosPaymentSettings.js";
import PosDisputes from "../Components/Settings/Pos/PosDisputes/PosDisputes.js";
import PosDisputesView from "../Components/Settings/Pos/PosDisputes/PosDisputesView.js";
import PosDisputesEvidenceType from "../Components/Settings/Pos/PosDisputes/PosDisputesEvidenceType.js";
import PosStripeReturnUrl from "../Components/Settings/Pos/PosStripeReturnUrl.js";
import PosClearanceSetup from "../Components/Settings/Pos/PosClearanceSetup.js";
import DiscountPackages from "../Components/Settings/Pos/Promotions/DiscountPackages/DiscountPackages.js";
import CreateEditDiscountPackage from "../Components/Settings/Pos/Promotions/DiscountPackages/CreateEditDiscountPackage.js";
import DiscountGroups from "../Components/Settings/Pos/Promotions/DiscountGroups/DiscountGroups.js";
import CreateEditDiscountGroups from "../Components/Settings/Pos/Promotions/DiscountGroups/CreateEditDiscountGroups.js";
import EGiftCards from "../Components/Settings/Pos/EgiftCards/EGiftCard.js";
import CreateEditEGiftCards from "../Components/Settings/Pos/EgiftCards/CreateEditEGiftCard.js";
import PosQuickButton from "../Components/Settings/Pos/PosQuickButtons/PosQuickButton.js";
import AddEditposQuickButton from "../Components/Settings/Pos/PosQuickButtons/AddEditposQuickButton.js";
import IntegrationTouchMD from "../Components/Settings/Integrations/TouchMd.js";
import Shopify from "../Components/Settings/Integrations/Shopify.js";
import IntegrationZoho from "../Components/Settings/Integrations/ZohoCrm.js";
import IntegrationHubspot from "../Components/Settings/Integrations/HubspotCrm.js";
import IntegrationMailchimp from "../Components/Settings/Integrations/MailChimp.js";
import IntegrationActiveCampaign from "../Components/Settings/Integrations/ActiveCampaign.js";
import IntegrationConstantContact from "../Components/Settings/Integrations/ConstantContact.js";
import ZapierIntegration from "../Components/Settings/Integrations/ZapierIntegration.js";
import CherryIntegration from "../Components/Settings/Integrations/CherryIntegration.js";
import AspireIntegration from "../Components/Settings/Integrations/AspireIntegration.js";
import LeadsApiIntegration from "../Components/Settings/Integrations/LeadsApiIntegration.js";
import TimeClock from "../Components/Settings/TimeClock/TimeClock.js";
import TimeClockSettings from "../Components/Settings/TimeClock/TimeClockSettings.js";
import Eprescription from "../Components/Settings/Eprescribe/Prescription.js";
import Prescribers from "../Components/Settings/Eprescribe/Prescribers.js";
import SupportUsers from "../Components/Settings/Eprescribe/SupportUsers.js";
import MDRoom from "../Components/Rooms/MDRoom.js";
import ProcedureConsentsDetail from "../Components/Rooms/Consents/ProcedureConsentsDetail.js";
import ProcedureQuestionnaireDetail from "../Components/Rooms/Questionnaire/ProcedureQuestionnaireDetail.js";
import ProviderConsents from "../Components/Rooms/Consents/ProviderConsents.js";
import ProviderQuestionnaires from "../Components/Rooms/Questionnaire/ProviderQuestionnaires.js";
import ProcedureDetail from "../Components/Procedure/ProcedureDetail/ProcedureDetail.js";
import Procedure from "../Components/Procedure/Procedure.js";
import ProcedureHealth from "../Components/Procedure/ProcedureHealth.js";
import ProcedureHealthDetail from "../Components/Procedure/ProcedureHealthDetail.js";
import ProcedureQuestionnaireList from "../Components/Procedure/ProcedureQuestionnaireList.js";
import ProcedureNotes from "../Components/ProcedureNotes/ProcedureNotes.js";
import ProcedureConsents from "../Components/ProcedureConsents/ProcedureConsents.js";
import ProcedurePrescriptionDetail from "../Components/ProcedurePrescriptions/ProcedurePrescriptionDetail.js";
import ClientNotes from "../Components/ClientNotes/ClientNotes.js";
import InvoiceDetails from "../Components/Invoices/InvoiceDetails.js";
import InvoiceView from "../Components/InvoiceView/InvoiceView.js";
import TreatmentMarkings from "../Components/TreatmentMarkings/TreatmentMarkings.js";
import TraceabilityInfo from "../Components/TraceabilityInfo/TraceabilityInfo.js";
import UpcomingAppointments from "../Components/UpcomingAppointments/UpcomingAppointments.js";
import MedicalHistory from "../Components/MedicalHistory/MedicalHistory.js";
import PaymentHistory from "../Components/PaymentHistory/PaymentHistory.js";
// eslint-disable-next-line import/no-restricted-paths
import ARLayout from "../_legacy/Containers/Layouts/ARLayout.js";
import Authorization from "../Utils/authorization.js";
import DotPhrases from "../Components/Settings/DotPhrases/DotPhrases.js";
import AddUpdateDotPhrase from "../Components/Settings/DotPhrases/AddUpdateDotPhrase.js";
import SalesInsights from "../Components/BusinessInsights/SalesInsights.js";
import InventoryInsights from "../Components/BusinessInsights/InventoryInsights.js";
import AppointmentsInsights from "../Components/BusinessInsights/AppointmentsInsights.js";
import PatientsInsights from "../Components/BusinessInsights/PatientsInsights.js";
import PatientsInsightReport from "../Components/BusinessInsights/Patients/PatientsInsightReport.js";
import AppointmentsInsightReport from "../Components/BusinessInsights/Appointments/AppointmentsInsightReport.js";
import InventoryInsightReport from "../Components/BusinessInsights/Inventory/InventoryInsightReport.js";
import SampleInsights from "../Components/BusinessInsights/SampleInsights.js";
import SalesInsightsReport from "../Components/BusinessInsights/Sales/SalesInsightsReport.js";
import ReputationInsights from "../Components/BusinessInsights/ReputationInsights.js";
import ReputationInsightReport from "../Components/BusinessInsights/Reputation/ReputationInsightReport.js";
import StaffInsights from "../Components/BusinessInsights/StaffInsights.js";
import StaffInsightReport from "../Components/BusinessInsights/Staff/StaffInsightReport.js";
import BiViewAllSurveys from "../Components/BusinessInsights/BiViewAllSurveys.js";
import BiSurveyData from "../Components/BusinessInsights/BiSurveyData.js";
import IntegrationReturnUrl from "../Components/Settings/Integrations/IntegrationReturnUrl.js";
import TimeCardInsights from "../Components/BusinessInsights/TimeCardInsights.js";
import ShareReportReturnUrl from "../Components/BusinessInsights/ShareReportReturnUrl.js";
import Meeting from "../Components/Meeting/Meeting.js";
import EditProcedureHealth from "../Components/Procedure/EditHealthProcedure.js";
import ProcedurePrescriptions from "../Components/Clients/ProcedurePrescriptions.js";
import StockAlertsEmails from "../Components/Inventory/StockAlertsEmails/StockAlertsEmails.js";
import AccountStatusRedirect from "../Components/AccountHoldRedirect/AccountStatusRedirect.js";
import EvolusIntegration from "../Components/Settings/Integrations/EvolusIntegration.js";
import ReferralSources from "../Components/Settings/ReportSettings/ReferralSources.js";
import CreateEvent from "../pages/Event/CreateEvent.js";
import EditEvent from "../pages/Event/EditEvent.js";
import { SettingsInbox } from "../pages/Settings/Inbox/SettingsInbox.js";
import {
  APPOINTMENT_ROUTES,
  BI_ROUTES,
  CLIENTS_ROUTES,
  DASHBOARD_ROUTES,
  INBOX_ROUTES,
  INVENTORY_ROUTES,
  MD_ROOM_ROUTES,
  PROVIDER_ROOM_ROUTES,
  REPORTS_ROUTES,
  ROUTES,
  SALES_ROUTES,
  SETTINGS_ROUTES,
  SURVEYS_ROUTES,
} from "../consts/routes.js";
import { SettingsDeletedPatients } from "../pages/Settings/DeletedPatients/SettingsDeletedPatients.js";
import { NonDupPatients } from "../pages/NonDupPatients/NonDupPatients.js";
import { SettingsDeletedSurveys } from "../pages/Settings/DeletedSurveys/SettingsDeletedSurveys.js";
import ProviderRoom from "../pages/ProviderRoom/ProviderRoom.js";
import SalesCheckoutInvoice from "../pages/Sales/CheckoutInvoice/SalesCheckoutInvoice.js";
import { Qualiphy } from "../pages/Settings/Integrations/Qualiphy/Qualiphy.js";
import { PERMISSIONS } from "../consts/api.js";
import { QualiphyPaidExamsReport } from "../Components/BusinessInsights/Patients/PatientsStaticReports/QualiphyPaidExamsReport/QualiphyPaidExamsReport";
import { RetentionRateReport } from "../Components/BusinessInsights/Appointments/AppointmentsStaticReports/RetentionRateReport/RetentionRateReport";
import { RetentionRateDetailsReport } from "../Components/BusinessInsights/Appointments/AppointmentsStaticReports/RetentionRateDetailsReport/RetentionRateDetailsReport";
import { ExpiredLiabilityReport } from "../Components/BusinessInsights/Sales/Reports/ShortTermLiability/StaticReports/Expired/ExpiredLiabilityReport.js";
import { ExpiringLiabilityReport } from "../Components/BusinessInsights/Sales/Reports/ShortTermLiability/StaticReports/Expiring/ExpiringLiabilityReport.js";
import PosReconciliation from "../Components/Settings/Pos/PosReconciliation/PosReconciliation";
import { PatientVitals } from "../pages/Patients/Vitals/PatientVitals.js";
import { NoClinicPatientsReport } from "../Components/BusinessInsights/Patients/PatientsStaticReports/NoClinicPatientsReport/NoClinicPatientsReport.js";
import ChartAudit from "../Components/BusinessInsights/ChartAudit.js";
import { AppointmentNoShows } from "../pages/Appointments/NoShows/NoShows.js";
import { SettingsSalesGoals } from "../pages/Settings/SalesGoals/SalesGoals.js";
import { SalesGoalMutate } from "../pages/Settings/SalesGoalMutate/SalesGoalMutate.js";
import { Efax } from "../pages/Settings/Integrations/Efax/Efax.js";
import { getIsEfaxIntegrationAccessible } from "../helpers/integrations.js";
import ClinicalDocumentationSettings from "../Components/Settings/ManageClinics/ClinicalDocumentationSettings/ClinicalDocumentationSettings.js";
import NewBusinessInsights from "../Components/NewBusinessInsights/NewBusinessInsights.js";
import { NewExpiredLiabilityReport } from "../Components/NewBusinessInsights/BusinessInsightsContent/Reports/ShortTermLiability/StaticReports/Expired/NewExpiredLiabilityReport.js";
import { NewExpiringLiabilityReport } from "../Components/NewBusinessInsights/BusinessInsightsContent/Reports/ShortTermLiability/StaticReports/Expiring/NewExpiringLiabilityReport.js";
import { SettingsDeletedNotes } from "../pages/Settings/DeletedNotes/DeletedNotes.js";
import { EfaxTemplates } from "../pages/Settings/ClinicalDocumentation/EfaxTemplates/EfaxTemplates.js";
import { EfaxTemplateCreateEdit } from "../pages/Settings/ClinicalDocumentation/EfaxTemplateCreateEdit/EfaxTemplateCreateEdit.js";
import { BiGiftCardHistory } from "../pages/BusinessInsights/GiftCardHistory/GiftCardHistory.js";
import { IntercomMessanger } from "../widgets/IntercomMessanger/IntercomMessanger.js";
import { PricingVariation } from "../pages/Inventory/PricingVariation/PricingVariation.js";
import QuickBooks from "../pages/Settings/Integrations/QuickBooks/QuickBooks.js";
import RepeatMd from "../pages/Settings/Integrations/RepeatMd/RepeatMd.js";
import { BetaProgram } from "../Components/Settings/BetaProgram/BetaProgram.js";
import ChartFilters from "../Components/Settings/ManageClinics/ChartFilters/ChartFilters.js";
import EfaxFavoriteCreateEdit from "../pages/Settings/ClinicalDocumentation/EfaxFavoriteCreateEdit/EfaxFavoriteCreateEdit.js";
import { getIsEvolusEnabled } from "../Utils/services.js";
import { ShopifyProducts } from "../pages/Inventory/ShopifyProducts/ShopifyProducts";
import { isShopifyAvailable } from "../helpers/featureFlags.js";

export default (
  <ErrorBoundary>
    <ARLayout>
      <AccountStatusRedirect />
      <IntercomMessanger />
      <Switch>
        <PrivateRoute
          exact
          path={DASHBOARD_ROUTES.root()}
          component={Authorization([
            "dashboard",
            "appointments",
            "admin",
            "dashboard",
            "dashboard",
          ])(Dashboard)}
        />
        <PrivateRoute
          exact
          path={DASHBOARD_ROUTES.smsNotifications()}
          component={Authorization([
            "access-all",
            "appointments",
            "admin",
            "settings",
          ])(SMSNotifications)}
        />
        <PrivateRoute
          exact
          path={INBOX_ROUTES.smsLeadAr()}
          component={Authorization([
            "access-all",
            "appointments",
            "admin",
            "settings",
          ])(ListSMS)}
        />
        <PrivateRoute
          exact
          path={INBOX_ROUTES.smsBlackList()}
          component={Authorization([
            "access-all",
            "appointments",
            "admin",
            "settings",
          ])(ListSMS)}
        />
        {getIsEfaxIntegrationAccessible() && (
          <PrivateRoute
            exact
            path={ROUTES.inbox.efaxOrders()}
            component={Authorization([
              "access-all",
              "appointments",
              "admin",
              "settings",
            ])(ListSMS)}
          />
        )}
        <PrivateRoute
          exact
          path={INBOX_ROUTES.smsByClinic(":inboxClinicId?")}
          component={Authorization([
            "access-all",
            "appointments",
            "admin",
            "settings",
          ])(ListSMS)}
        />
        <PrivateRoute
          exact
          path={INBOX_ROUTES.smsCreate(":id")}
          component={Authorization([
            "access-all",
            "appointments",
            "admin",
            "settings",
          ])(CreateSMS)}
        />
        <PrivateRoute
          exact
          path={INBOX_ROUTES.smsEdit(":smsId")}
          component={Authorization([
            "access-all",
            "appointments",
            "admin",
            "settings",
          ])(CreateSMS)}
        />
        <PrivateRoute
          exact
          path={INBOX_ROUTES.lists()}
          component={Authorization([
            "access-all",
            "appointments",
            "admin",
            "settings",
          ])(List)}
        />
        <PrivateRoute
          exact
          path={DASHBOARD_ROUTES.smsNotificationsPopup(":id")}
          component={Authorization([
            "access-all",
            "appointments",
            "admin",
            "settings",
          ])(SMSNotificationsPopup)}
        />
        <PrivateRoute
          exact
          path={DASHBOARD_ROUTES.notifications()}
          component={Authorization([
            "access-all",
            "appointments",
            "admin",
            "settings",
          ])(Notifications)}
        />
        <PrivateRoute
          exact
          path={INBOX_ROUTES.campaign()}
          component={Authorization([
            "access-all",
            "appointments",
            "admin",
            "settings",
          ])(SmsCampaign)}
        />
        <PrivateRoute
          exact
          path={INBOX_ROUTES.campaignView(":listID")}
          component={Authorization([
            "access-all",
            "appointments",
            "admin",
            "settings",
          ])(SmsCampaign)}
        />
        <PrivateRoute
          exact
          path={INBOX_ROUTES.templates()}
          component={Authorization([
            "access-all",
            "appointments",
            "admin",
            "settings",
          ])(SmsTemplate)}
        />
        <PrivateRoute
          exact
          path={CLIENTS_ROUTES.root()}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "clients",
          ])(Clients)}
        />
        <PrivateRoute
          exact
          path={CLIENTS_ROUTES.mergeProfiles()}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "clients",
          ])(MergeProfile)}
        />
        <PrivateRoute
          exact
          path={CLIENTS_ROUTES.procedure({
            clientId: ":id",
            isNotReloadClientProfile: ":isNotReloadClientProfile?",
            isPrescribed: ":isPrescribed",
            procedureId: ":procedureID",
          })}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "clients",
          ])(ClientProfile)}
        />
        <PrivateRoute
          exact
          path={CLIENTS_ROUTES.profileWithReloadFlag({
            id: ":id",
            isNotReloadClientProfile: ":isNotReloadClientProfile?",
          })}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "clients",
          ])(ClientProfile)}
        />
        <PrivateRoute
          exact
          path={CLIENTS_ROUTES.vitals(":profileId")}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "clients",
          ])(PatientVitals)}
        />
        <PrivateRoute
          exact
          path={CLIENTS_ROUTES.create()}
          component={Authorization([
            "add-update-patients",
            "patients-management",
            "admin",
            "clients",
          ])(CreateEditClients)}
        />
        <PrivateRoute
          exact
          path={CLIENTS_ROUTES.clientByType({ id: ":id", type: ":type" })}
          component={Authorization([
            "add-update-patients",
            "patients-management",
            "admin",
            "clients",
          ])(CreateEditClients)}
        />
        <PrivateRoute
          exact
          path={ROUTES.leads()}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "clients",
          ])(Leads)}
        />
        <PrivateRoute
          exact
          path={CLIENTS_ROUTES.wallet({
            actionType: ":actionType",
            id: ":clientID",
            type: ":type",
          })}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "clients",
          ])(ClientWallet)}
        />
        <PrivateRoute
          exact
          path={CLIENTS_ROUTES.documentView({
            actionType: ":actionType",
            resourceType: ":resourceType",
            documentId: ":documentID?",
            clientId: ":clientID",
            type: ":type",
          })}
          component={Authorization([
            "add-update-documents",
            "patients-management",
            "admin",
            "procedure",
          ])(CreateEditClientDocuments)}
        />
        <PrivateRoute
          exact
          path={CLIENTS_ROUTES.documents({
            actionType: ":actionType",
            clientId: ":clientID",
            type: ":type",
          })}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "procedure",
          ])(ClientDocuments)}
        />
        <PrivateRoute
          exact
          path="/:actionType/fax/:clientID/:type"
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "procedure",
          ])(ClientFax)}
        />
        <PrivateRoute
          exact
          path={CLIENTS_ROUTES.exportProcedures({
            actionType: ":actionType",
            clientId: ":clientID",
            type: ":type",
          })}
          component={Authorization([
            "export-procedures",
            "patients-management",
            "admin",
            "clients",
          ])(ExportProcedures)}
        />
        <PrivateRoute
          exact
          path={CLIENTS_ROUTES.bulkImport()}
          component={Authorization([
            "add-update-patients",
            "patients-management",
            "admin",
            "procedure",
          ])(BulkImport)}
        />
        <PrivateRoute
          exact
          path={CLIENTS_ROUTES.scan()}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "clients",
          ])(ScanComponent)}
        />
        <PrivateRoute
          exact
          path={ROUTES.exportProcedure({
            actionType: ":actionType",
            clientId: ":clientID",
            type: ":type",
            procedureId: ":procedureID",
            appointmentId: ":appointmentID",
          })}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "clients",
          ])(ViewFilledSurvey)}
        />
        <PrivateRoute
          exact
          path={CLIENTS_ROUTES.appointmentQuestionnaire({
            clientId: ":clientID",
            consultationId: ":consultationID",
            appointmentId: ":appointmentID",
            serviceId: ":serviceID",
          })}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "clients",
          ])(DocumentQuestionnaire)}
        />
        <PrivateRoute
          exact
          path={CLIENTS_ROUTES.appointmentHealthQuestionnaire({
            clientId: ":clientID",
            procedureId: ":procedureID",
            type: ":type",
            appointmentId: ":appointmentID",
          })}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "clients",
          ])(DocumentHealthQuestionnaires)}
        />
        <PrivateRoute
          exact
          path={CLIENTS_ROUTES.procedureInvoice({
            type: ":type",
            procedureId: ":procedureID",
          })}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "clients",
          ])(HealthInvoiceCreation)}
        />
        <PrivateRoute
          exact
          path={ROUTES.appointmentQuestionnaire(":appointmentID")}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "clients",
          ])(AppointmentQuestionnaires)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.serviceCategory()}
          component={Authorization([
            "manage-appointment-settings",
            "appointments",
            "admin",
            "appointments",
          ])(ServiceCategory)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.createServiceCategory()}
          component={Authorization([
            "manage-appointment-settings",
            "appointments",
            "admin",
            "appointments",
          ])(CreateEditServiceCategory)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.editServiceCategory(":id")}
          component={Authorization([
            "manage-appointment-settings",
            "appointments",
            "admin",
            "appointments",
          ])(CreateEditServiceCategory)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.services()}
          component={Authorization([
            "manage-appointment-settings",
            "appointments",
            "admin",
            "appointments",
          ])(Services)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.servicesMain()}
          component={Authorization([
            "manage-appointment-settings",
            "appointments",
            "admin",
            "appointments",
          ])(ServicesMain)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.otherSettings()}
          component={Authorization([
            "manage-appointment-settings",
            "appointments",
            "admin",
            "appointments",
          ])(OtherSettings)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.createService()}
          component={Authorization([
            "manage-appointment-settings",
            "appointments",
            "admin",
            "appointments",
          ])(CreateEditServices)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.editService({
            serviceCategoryId: ":serviceCategoryId",
            id: ":id",
          })}
          component={Authorization([
            "manage-appointment-settings",
            "appointments",
            "admin",
            "appointments",
          ])(CreateEditServices)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.cloneService({
            serviceCategoryId: ":serviceCategoryId",
            id: ":cloneId",
          })}
          component={Authorization([
            "manage-appointment-settings",
            "appointments",
            "admin",
            "appointments",
          ])(CreateEditServices)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.servicePackages()}
          component={Authorization([
            "manage-appointment-settings",
            "appointments",
            "admin",
            "appointments",
          ])(ServicesPackages)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.createServicePackage()}
          component={Authorization([
            "manage-appointment-settings",
            "appointments",
            "admin",
            "appointments",
          ])(CreateEditServicesPackages)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.editServicePackage(":id")}
          component={Authorization([
            "manage-appointment-settings",
            "appointments",
            "admin",
            "appointments",
          ])(CreateEditServicesPackages)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.cloneServicePackage(":cloneId")}
          component={Authorization([
            "manage-appointment-settings",
            "appointments",
            "admin",
            "appointments",
          ])(CreateEditServicesPackages)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.calendar()}
          component={Authorization([
            "view-appointments",
            "appointments",
            "admin",
            "appointments",
          ])(NewCalendar)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.calendarDate(":date")}
          component={Authorization([
            "view-appointments",
            "appointments",
            "admin",
            "appointments",
          ])(NewCalendar)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.calendarProviderView({
            providerId: ":providerId",
            clinicId: ":clinicId",
            view: ":view",
          })}
          component={Authorization([
            "view-appointments",
            "appointments",
            "admin",
            "appointments",
          ])(NewCalendar)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.calendarProviderViewDate({
            providerId: ":providerId",
            clinicId: ":clinicId",
            view: ":view",
            date: ":date",
          })}
          component={Authorization([
            "view-appointments",
            "appointments",
            "admin",
            "appointments",
          ])(NewCalendar)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.providerWeekCalendar()}
          component={Authorization([
            "view-appointments",
            "appointments",
            "admin",
            "appointments",
          ])(ProviderWeekCalendar)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.providerWeekCalendarDate({
            date: ":date",
            clinicId: ":clinicId",
          })}
          component={Authorization([
            "view-appointments",
            "appointments",
            "admin",
            "appointments",
          ])(ProviderWeekCalendar)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.calendarAppointment(":appointmentId")}
          component={Authorization([
            "view-appointments",
            "appointments",
            "admin",
            "appointments",
          ])(NewCalendar)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.equipmentSchedule()}
          component={Authorization([
            "manage-appointment-settings",
            "appointments",
            "admin",
            "appointments",
          ])(OtherResources)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.createEquipmentSchedule()}
          component={Authorization([
            "manage-appointment-settings",
            "appointments",
            "admin",
            "appointments",
          ])(CreateEditEquipmentSchedule)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.editEquipmentSchedule(":id")}
          component={Authorization([
            "manage-appointment-settings",
            "appointments",
            "admin",
            "appointments",
          ])(CreateEditEquipmentSchedule)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.resourceSchedule()}
          component={Authorization([
            "manage-appointment-settings",
            "appointments",
            "admin",
            "appointments",
          ])(ResourceSchedule)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.createResourceSchedule()}
          component={Authorization([
            "manage-appointment-settings",
            "appointments",
            "admin",
            "appointments",
          ])(CreateEditResourceSchedule)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.editResourceSchedule(":id")}
          component={Authorization([
            "manage-appointment-settings",
            "appointments",
            "admin",
            "appointments",
          ])(CreateEditResourceSchedule)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.providerSchedule()}
          component={Authorization([
            "manage-appointment-settings",
            "appointments",
            "admin",
            "appointments",
          ])(ProviderSchedule)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.viewProviderSchedule(":id")}
          component={Authorization([
            "manage-appointment-settings",
            "appointments",
            "admin",
            "appointments",
          ])(ProviderScheduleView)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.deleteProviderSchedule(":id")}
          component={Authorization([
            "manage-appointment-settings",
            "appointments",
            "admin",
            "appointments",
          ])(ProviderScheduleDelete)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.createWithProvider(":providerId")}
          component={Authorization([
            "create-appointment",
            "appointments",
            "admin",
            "appointments",
          ])(CreateEvent)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.createWithClient(":clientId")}
          component={Authorization([
            "create-appointment",
            "appointments",
            "admin",
            "appointments",
          ])(CreateEvent)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.createWithLead(":leadId")}
          component={Authorization([
            "create-appointment",
            "appointments",
            "admin",
            "appointments",
          ])(CreateEvent)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.noShows()}
          component={Authorization([
            "create-appointment",
            "appointments",
            "admin",
            "appointments",
          ])(AppointmentNoShows)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.createWithProviderClinicDateTime({
            providerId: ":providerId",
            clinicId: ":clinicId",
            date: ":date",
            time: ":time",
          })}
          component={Authorization([
            "create-appointment",
            "appointments",
            "admin",
            "appointments",
          ])(CreateEvent)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.edit({
            editEventId: ":editEventId",
            clinicId: ":clinicId?",
          })}
          component={Authorization([
            "create-appointment",
            "appointments",
            "admin",
            "appointments",
          ])(EditEvent)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.editWithMode({
            editEventId: ":editEventId",
            mode: ":mode",
            clinicId: ":clinicId?",
          })}
          component={Authorization([
            "create-appointment",
            "appointments",
            "admin",
            "appointments",
          ])(EditEvent)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.editWithModeTime({
            editEventId: ":editEventId",
            mode: ":mode",
            time: ":time",
            clinicId: ":clinicId?",
          })}
          component={Authorization([
            "create-appointment",
            "appointments",
            "admin",
            "appointments",
          ])(EditEvent)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.config()}
          component={Authorization([
            "manage-appointment-settings",
            "appointments",
            "admin",
            "appointments",
            "privileges-level-11",
          ])(AppointmentConfig)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.communications()}
          component={Authorization([
            "manage-appointment-settings",
            "appointments",
            "admin",
            "appointments",
          ])(AppointmentCommunication)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.bookingPortal()}
          component={Authorization([
            "manage-appointment-settings",
            "appointments",
            "admin",
            "appointments",
          ])(BookingPortal)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.bookingHistory()}
          component={Authorization([
            "view-appointments",
            "appointments",
            "admin",
            "appointments",
          ])(BookingHistory)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.reports()}
          component={Authorization([
            "view-appointments",
            "appointments",
            "admin",
            "appointments",
          ])(AppointmentReports)}
        />
        <PrivateRoute
          exact
          path={APPOINTMENT_ROUTES.verticals()}
          component={Authorization([
            "manage-verticals",
            "appointments",
            "admin",
            "appointments",
          ])(Verticals)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.invoiceDisclaimer()}
          component={Authorization([
            "view-sales-invoice-text",
            "settings",
            "admin",
            "sales",
          ])(InvoiceDisclaimerText)}
        />
        <PrivateRoute
          exact
          path={SALES_ROUTES.virtualTerminal()}
          component={Authorization([
            "virtual-terminal",
            "sales",
            "admin",
            "sales",
          ])(FrontdeskCheckout)}
        />
        <PrivateRoute
          exact
          path={SALES_ROUTES.root()}
          component={Authorization([
            "view-sales-invoices",
            "sales",
            "admin",
            "sales",
          ])(SalesInvoices)}
        />
        <PrivateRoute
          exact
          path={SALES_ROUTES.invoices({
            filterType: ":filterType?",
            filterId: ":filterID?",
          })}
          component={Authorization([
            "view-sales-invoices",
            "sales",
            "admin",
            "sales",
          ])(SalesInvoices)}
        />
        <PrivateRoute
          exact
          path={SALES_ROUTES.invoicePopups()}
          component={Authorization([
            "view-sales-invoices",
            "sales",
            "admin",
            "sales",
          ])(SalesInvoicesPopups)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.addSalesGoal()}
          component={Authorization([
            "view-sales-goals",
            "settings",
            "admin",
            "sales",
          ])(SalesGoalMutate)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.salesGoals()}
          component={Authorization([
            "view-sales-goals",
            "settings",
            "admin",
            "sales",
          ])(SettingsSalesGoals)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.editSalesGoal({
            id: ":id",
            clinicId: ":clinicId",
          })}
          component={Authorization([
            "view-sales-goals",
            "settings",
            "admin",
            "sales",
          ])(SalesGoalMutate)}
        />
        <PrivateRoute
          exact
          path={SALES_ROUTES.cashDrawer()}
          component={Authorization([
            "view-cash-drawer",
            "sales",
            "admin",
            "sales",
          ])(CashDrawer)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.dashboardSettings()}
          component={Authorization([
            "view-sales-goals",
            "settings",
            "admin",
            "sales",
          ])(DashboardRoles)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.referralSources()}
          component={Authorization(["access-all", "settings", "admin"])(
            ReferralSources,
          )}
        />
        <PrivateRoute
          exact
          path={INVENTORY_ROUTES.activeProductsSearch(":searchTerm?")}
          component={Authorization([
            "view-products-inventory",
            "inventory-management",
            "admin",
            "dashboard",
            "inventory",
          ])(InventoryProductsActive)}
        />
        <PrivateRoute
          exact
          path={INVENTORY_ROUTES.reconciliation()}
          component={Authorization([
            "view-products-inventory",
            "inventory-management",
            "admin",
            "dashboard",
            "inventory",
          ])(InventoryReconciliation)}
        />
        {isShopifyAvailable() && (
          <PrivateRoute
            exact
            path={INVENTORY_ROUTES.shopifyProducts()}
            component={Authorization([
              "view-products-inventory",
              "inventory-management",
              "admin",
              "dashboard",
              "inventory",
            ])(ShopifyProducts)}
          />
        )}
        <PrivateRoute
          exact
          path={INVENTORY_ROUTES.priceList()}
          component={Authorization([
            "view-products-inventory",
            "inventory-management",
            "admin",
            "dashboard",
            "inventory",
          ])(Pricelist)}
        />
        <PrivateRoute
          exact
          path={INVENTORY_ROUTES.inactiveProducts()}
          component={Authorization([
            "view-products-inventory",
            "inventory-management",
            "admin",
            "dashboard",
            "inventory",
          ])(InventoryProductsInactive)}
        />
        <PrivateRoute
          exact
          path={INVENTORY_ROUTES.editProductWithStatus({
            statusId: ":statusId",
            id: ":id",
          })}
          component={Authorization([
            "edit-product",
            "inventory-management",
            "admin",
            "dashboard",
            "inventory",
          ])(EditInventory)}
        />
        <PrivateRoute
          exact
          path={INVENTORY_ROUTES.editProduct(":id")}
          component={Authorization([
            "edit-product",
            "inventory-management",
            "admin",
            "dashboard",
            "inventory",
          ])(EditInventory)}
        />
        <PrivateRoute
          exact
          path={INVENTORY_ROUTES.editProductWithStock({
            id: ":id",
            isManageStock: ":isManageStock",
          })}
          component={Authorization([
            "manage-stocks",
            "inventory-management",
            "admin",
            "dashboard",
            "inventory",
          ])(EditInventory)}
        />
        <PrivateRoute
          exact
          path={INVENTORY_ROUTES.addProduct()}
          component={Authorization([
            "add-product",
            "inventory-management",
            "admin",
            "dashboard",
            "inventory",
          ])(EditInventory)}
        />
        <PrivateRoute
          exact
          path={INVENTORY_ROUTES.productCategories()}
          component={Authorization([
            "view-product-categories",
            "inventory-management",
            "admin",
            "dashboard",
            "inventory",
          ])(ProductCategories)}
        />
        <PrivateRoute
          exact
          path={INVENTORY_ROUTES.createProductCategory()}
          component={Authorization([
            "manage-product-categories",
            "inventory-management",
            "admin",
            "dashboard",
          ])(CreateEditCategories)}
        />
        <PrivateRoute
          exact
          path={INVENTORY_ROUTES.editProductCategory(":id")}
          component={Authorization([
            "manage-product-categories",
            "inventory-management",
            "admin",
            "dashboard",
          ])(CreateEditCategories)}
        />
        <PrivateRoute
          exact
          path={INVENTORY_ROUTES.treatmentPlanTemplates()}
          component={Authorization([
            "view-products-inventory",
            "inventory-management",
            "admin",
            "dashboard",
            "inventory",
          ])(TreatmentPlanTemplate)}
        />
        <PrivateRoute
          exact
          path={INVENTORY_ROUTES.createTreatentPlanTemplate()}
          component={Authorization([
            "view-products-inventory",
            "inventory-management",
            "admin",
            "dashboard",
            "inventory",
          ])(CreateTreatmentPlanTemplate)}
        />
        <PrivateRoute
          exact
          path={INVENTORY_ROUTES.productCategory(":categoryId")}
          component={Authorization([
            "view-product-categories",
            "inventory-management",
            "admin",
            "dashboard",
            "inventory",
          ])(InventoryProductsCategory)}
        />
        <PrivateRoute
          exact
          path={INVENTORY_ROUTES.pricingVariation()}
          component={Authorization([
            "view-charting-package",
            "inventory-management",
            "admin",
            "dashboard",
            "inventory",
          ])(PricingVariation)}
        />
        <PrivateRoute
          exact
          path={INVENTORY_ROUTES.createPricingVariation()}
          component={Authorization([
            "view-charting-package",
            "inventory-management",
            "admin",
            "dashboard",
            "inventory",
          ])(CreateEditChartingPackages)}
        />
        <PrivateRoute
          exact
          path={INVENTORY_ROUTES.editPricingVariation(":id")}
          component={Authorization([
            "view-charting-package",
            "inventory-management",
            "admin",
            "dashboard",
            "inventory",
          ])(CreateEditChartingPackages)}
        />
        <PrivateRoute
          exact
          path={INVENTORY_ROUTES.suppliers()}
          component={Authorization([
            "view-products-inventory",
            "inventory-management",
            "admin",
            "dashboard",
            "inventory",
          ])(Suppliers)}
        />
        <PrivateRoute
          exact
          path={INVENTORY_ROUTES.purchaseOrder()}
          component={Authorization([
            "view-products-inventory",
            "inventory-management",
            "admin",
            "dashboard",
            "inventory",
          ])(PurchaseOrder)}
        />
        <PrivateRoute
          exact
          path={INVENTORY_ROUTES.supplier(":id")}
          component={Authorization([
            "view-products-inventory",
            "inventory-management",
            "admin",
            "dashboard",
            "inventory",
          ])(ViewEditSupplier)}
        />
        <PrivateRoute
          path={INVENTORY_ROUTES.importSuppliers()}
          component={Authorization([
            "view-products-inventory",
            "inventory-management",
            "admin",
            "dashboard",
            "inventory",
          ])(ImportSupplier)}
        />
        <PrivateRoute
          exact
          path={INVENTORY_ROUTES.purchaseOrderView(":id")}
          component={Authorization([
            "view-products-inventory",
            "inventory-management",
            "admin",
            "dashboard",
            "inventory",
          ])(PurchaseOrderDetails)}
        />
        <PrivateRoute
          exact
          path={INVENTORY_ROUTES.addPurchaseOrderById(":id")}
          component={Authorization([
            "view-products-inventory",
            "inventory-management",
            "admin",
            "dashboard",
            "inventory",
          ])(CreatePurchaseOrder)}
        />
        <PrivateRoute
          exact
          path={INVENTORY_ROUTES.addPurchaseOrder()}
          component={Authorization([
            "view-products-inventory",
            "inventory-management",
            "admin",
            "dashboard",
            "inventory",
          ])(CreatePurchaseOrder)}
        />
        <PrivateRoute
          exact
          path={INVENTORY_ROUTES.stockTransfers()}
          component={Authorization([
            "view-products-inventory",
            "inventory-management",
            "admin",
            "dashboard",
            "inventory",
          ])(StockTransfer)}
        />
        <PrivateRoute
          exact
          path={INVENTORY_ROUTES.stockAlertEmails()}
          component={Authorization([
            "view-products-inventory",
            "inventory-management",
            "admin",
            "dashboard",
            "inventory",
          ])(StockAlertsEmails)}
        />
        <PrivateRoute
          exact
          path={INVENTORY_ROUTES.editStockTransfer(":id")}
          component={Authorization([
            "view-products-inventory",
            "inventory-management",
            "admin",
            "dashboard",
            "inventory",
          ])(CreateEditStockTransfer)}
        />
        <PrivateRoute
          exact
          path={INVENTORY_ROUTES.createStockTransfer()}
          component={Authorization([
            "view-products-inventory",
            "inventory-management",
            "admin",
            "dashboard",
            "inventory",
          ])(CreateEditStockTransfer)}
        />
        <PrivateRoute
          exact
          path={INVENTORY_ROUTES.viewStockTransfer(":id")}
          component={Authorization([
            "view-products-inventory",
            "inventory-management",
            "admin",
            "dashboard",
            "inventory",
          ])(StockTransferDetails)}
        />
        <PrivateRoute
          exact
          path={REPORTS_ROUTES.root()}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "settings",
            "reports",
          ])(Reports)}
        />
        <PrivateRoute
          exact
          path={REPORTS_ROUTES.create()}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "settings",
            "reports",
          ])(CreateReports)}
        />
        <PrivateRoute
          exact
          path={REPORTS_ROUTES.edit(":id")}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "settings",
            "reports",
          ])(ReportEdit)}
        />
        <PrivateRoute
          exact
          path={SURVEYS_ROUTES.dashboardViewAll()}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "surveys",
          ])(ViewAllSurveys)}
        />
        <PrivateRoute
          exact
          path={SURVEYS_ROUTES.view(":id")}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "surveys",
          ])(SurveyData)}
        />
        <PrivateRoute
          exact
          path={SURVEYS_ROUTES.dashboardView(":id")}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "surveys",
          ])(ViewAllSurveys)}
        />
        <PrivateRoute
          exact
          path={SURVEYS_ROUTES.dashboard()}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "surveys",
          ])(AllSurveys)}
        />
        <PrivateRoute
          exact
          path={SURVEYS_ROUTES.manage()}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "surveys",
          ])(SurveyList)}
        />
        <PrivateRoute
          exact
          path={SURVEYS_ROUTES.createTemplate()}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "surveys",
          ])(CreateEditSurveyTemplate)}
        />
        <PrivateRoute
          exact
          path={SURVEYS_ROUTES.editTemplate(":id")}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "surveys",
          ])(CreateEditSurveyTemplate)}
        />
        <PrivateRoute
          exact
          path={DASHBOARD_ROUTES.userLogs()}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "dashboard",
          ])(UserActivity)}
        />
        <PrivateRoute
          exact
          path={DASHBOARD_ROUTES.viewUserLogChanges({
            objectType: ":object_type",
            childId: ":child_id",
            objectId: ":object_id",
          })}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "dashboard",
          ])(ViewComparison)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.profile()}
          component={Authorization([
            "access-all",
            "patients-management",
            "admin",
            "settings",
          ])(Profile)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.arAccount()}
          component={Authorization([
            "manage-account-information",
            "settings",
            "admin",
            "settings",
          ])(Araccount)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.payrollCommissionReport()}
          component={Authorization([
            "superadmin",
            "settings",
            "admin",
            "settings",
            "privileges-level-5",
          ])(Payroll)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.arTermsOfUse()}
          component={Authorization([
            "manage-account-information",
            "settings",
            "admin",
            "settings",
          ])(ArTermsOfUse)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.hipaaTermsOfUse()}
          component={Authorization([
            "manage-account-information",
            "settings",
            "admin",
            "settings",
          ])(HipaaTermsOfUse)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.syncProfileCalendar()}
          component={Authorization([
            "access-all",
            "patients-management",
            "admin",
            "settings",
          ])(GoogleCalendarReturnUrl)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.surveys()}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "surveys",
          ])(SettingsSurveyList)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.createSurveyTemplate()}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "surveys",
          ])(SettingsCreateEditSurveyTemplate)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.editSurveyTemplate(":id")}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "surveys",
          ])(SettingsCreateEditSurveyTemplate)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.viewAllSurveys(":id?")}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "surveys",
          ])(ViewAllSurveys)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.viewSurvey({
            surveyId: ":surveyID",
            id: ":id",
          })}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "surveys",
          ])(SurveyData)}
        />
        <PrivateRoute
          path={SETTINGS_ROUTES.cosmeticQuestionnaires()}
          exact
          component={Authorization([
            "manage-questionnaires",
            "settings",
            "admin",
            "settings",
          ])(Questionnaires)}
        />
        <PrivateRoute
          path={SETTINGS_ROUTES.createCosmeticQuestionnaire()}
          exact
          component={Authorization([
            "manage-questionnaires",
            "settings",
            "admin",
            "settings",
          ])(CreateEditQuestionnaire)}
        />
        <PrivateRoute
          path={SETTINGS_ROUTES.editCosmeticQuestionnaire(":id")}
          exact
          component={Authorization([
            "manage-questionnaires",
            "settings",
            "admin",
            "settings",
          ])(CreateEditQuestionnaire)}
        />
        <PrivateRoute
          path={SETTINGS_ROUTES.consents()}
          component={Authorization([
            "manage-consents",
            "settings",
            "admin",
            "settings",
          ])(Consents)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.editConsent(":id")}
          component={Authorization([
            "manage-consents",
            "settings",
            "admin",
            "settings",
          ])(CreateEditConsents)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.createConsent()}
          component={Authorization([
            "manage-consents",
            "settings",
            "admin",
            "settings",
          ])(CreateEditConsents)}
        />
        <PrivateRoute
          path={SETTINGS_ROUTES.clinics()}
          component={Authorization([
            "manage-clinics",
            "settings",
            "admin",
            "settings",
          ])(Clinics)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.editClinic(":id")}
          component={Authorization([
            "manage-clinics",
            "settings",
            "admin",
            "settings",
          ])(CreateEditClinics)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.createClinic()}
          component={Authorization([
            "manage-clinics",
            "settings",
            "admin",
            "settings",
          ])(CreateEditClinics)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.createProcedureTemplate()}
          component={Authorization([
            "manage-questionnaires",
            "settings",
            "admin",
            "settings",
          ])(CreateEditTemplate)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.editProcedureTemplate(":id")}
          component={Authorization([
            "manage-questionnaires",
            "settings",
            "admin",
            "settings",
          ])(CreateEditTemplate)}
        />
        <PrivateRoute
          path={SETTINGS_ROUTES.healthTimelineQuestionnaires()}
          component={Authorization([
            "manage-questionnaires",
            "settings",
            "admin",
            "settings",
          ])(ProcedureTemplates)}
        />
        <PrivateRoute
          path={SETTINGS_ROUTES.createPostTreatmentInstruction()}
          component={Authorization([
            "manage-post-treatment-instructions",
            "settings",
            "admin",
            "dashboard",
          ])(CreateEditPostInstructions)}
        />
        <PrivateRoute
          path={SETTINGS_ROUTES.editPostTreatmentInstruction(":id")}
          component={Authorization([
            "manage-post-treatment-instructions",
            "settings",
            "admin",
            "dashboard",
          ])(CreateEditPostInstructions)}
        />
        <PrivateRoute
          path={SETTINGS_ROUTES.postTreatment()}
          exact
          component={Authorization([
            "manage-post-treatment-instructions",
            "settings",
            "admin",
            "settings",
          ])(PostTreatmentInstructions)}
        />
        <PrivateRoute
          path={SETTINGS_ROUTES.postTreatmentEmail()}
          exact
          component={Authorization([
            "manage-post-treatment-instructions",
            "settings",
            "admin",
            "settings",
          ])(PostTreatmentEmail)}
        />
        <PrivateRoute
          path={SETTINGS_ROUTES.preTreatmentInstructions()}
          exact
          component={Authorization([
            "manage-pre-treatment-instructions",
            "settings",
            "admin",
            "settings",
          ])(PreTreatmentInstructions)}
        />
        <PrivateRoute
          path={SETTINGS_ROUTES.preTreatmentEmail()}
          exact
          component={Authorization([
            "manage-pre-treatment-instructions",
            "settings",
            "admin",
            "settings",
          ])(PreTreatmentEmail)}
        />
        <PrivateRoute
          path={SETTINGS_ROUTES.createPreTreatmentInstruction()}
          component={Authorization([
            "manage-pre-treatment-instructions",
            "settings",
            "admin",
            "dashboard",
          ])(CreateEditPreInstructions)}
        />
        <PrivateRoute
          path={SETTINGS_ROUTES.editPreTreatmentInstruction(":id")}
          component={Authorization([
            "manage-pre-treatment-instructions",
            "settings",
            "admin",
            "dashboard",
          ])(CreateEditPreInstructions)}
        />
        <PrivateRoute
          path={SETTINGS_ROUTES.treatmentPlanTemplates()}
          exact
          component={Authorization([
            "access-all",
            "settings",
            "admin",
            "settings",
          ])(TreatmentPlanTemplates)}
        />
        <PrivateRoute
          path={SETTINGS_ROUTES.editTreatmentPlanTemplate(":id")}
          exact
          component={Authorization([
            "access-all",
            "settings",
            "admin",
            "settings",
          ])(CreateEditTreatmentPlanTemp)}
        />
        <PrivateRoute
          path={SETTINGS_ROUTES.createTreatmentPlanTemplate()}
          exact
          component={Authorization([
            "access-all",
            "settings",
            "admin",
            "settings",
          ])(CreateEditTreatmentPlanTemp)}
        />
        <PrivateRoute
          path="/settings/clinical-documentation-settings"
          exact
          component={ClinicalDocumentationSettings}
        />
        <PrivateRoute
          path={SETTINGS_ROUTES.chartFilters()}
          exact
          component={ChartFilters}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.membershipAgreements()}
          component={Authorization([
            PERMISSIONS.settings.manageMembershipAgreements,
            "settings",
            "admin",
            "settings",
          ])(MembershipAgreement)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.editMembershipAgreement(":id")}
          component={Authorization([
            PERMISSIONS.settings.manageMembershipAgreements,
            "settings",
            "admin",
            "settings",
          ])(CreateEditMembershipAgreement)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.createMembershipAgreement()}
          component={Authorization([
            PERMISSIONS.settings.manageMembershipAgreements,
            "settings",
            "admin",
            "settings",
          ])(CreateEditMembershipAgreement)}
        />
        <PrivateRoute
          path={SETTINGS_ROUTES.appointmentEmailSms()}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "settings",
          ])(AppointmentEmailsSMS)}
        />
        <PrivateRoute
          path={SETTINGS_ROUTES.appointments()}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "settings",
          ])(AppointmentEmailsSMS)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.appointmentReminder()}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "settings",
          ])(AppointmentReminder)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.createAppointmentReminder()}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "settings",
          ])(CreateEditReminder)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.editAppointmentReminder(":reminderId")}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "settings",
          ])(CreateEditReminder)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.configureUri()}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "settings",
          ])(ConfigureURL)}
        />
        <Route
          exact
          path={SETTINGS_ROUTES.cancellationPolicy()}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "settings",
          ])(CancellationPolicy)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.patientPortal()}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "settings",
          ])(PatientPortal)}
        />
        <PrivateRoute
          path={SETTINGS_ROUTES.surveyEmailSms()}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "settings",
          ])(SurveySettings)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.membershipWallet()}
          component={Authorization([
            "access-all",
            "settings",
            "admin",
            "settings",
          ])(MembershipWallet)}
        />
        <PrivateRoute
          path={SETTINGS_ROUTES.users()}
          exact
          component={Authorization([
            "manage-users",
            "settings",
            "admin",
            "settings",
          ])(Users)}
        />
        <PrivateRoute
          path={SETTINGS_ROUTES.createUser()}
          component={Authorization([
            "manage-users",
            "settings",
            "admin",
            "settings",
          ])(CreateEditUser)}
        />
        <PrivateRoute
          path={SETTINGS_ROUTES.editUser(":userId")}
          component={Authorization([
            "manage-users",
            "settings",
            "admin",
            "settings",
          ])(CreateEditUser)}
        />
        <PrivateRoute
          path={SETTINGS_ROUTES.userControls()}
          exact
          component={Authorization([
            "manage-user-roles",
            "settings",
            "admin",
            "settings",
          ])(UserRoles)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.editUserPrivilege({
            id: ":id",
            roleId: ":roleId",
          })}
          component={Authorization([
            "manage-user-roles",
            "settings",
            "admin",
            "settings",
          ])(UserPrivilege)}
        />
        <PrivateRoute
          path={SETTINGS_ROUTES.deletedProcedures()}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "settings",
          ])(RecentlyDeleted)}
        />
        <PrivateRoute
          path={SETTINGS_ROUTES.deletedPaperwork()}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "settings",
          ])(Paperwork)}
        />
        <PrivateRoute
          path={SETTINGS_ROUTES.timeClock.list()}
          component={Authorization([
            "view-time-clock",
            "patients-management",
            "admin",
            "settings",
            "privileges-level-9",
          ])(TimeClock)}
        />
        <PrivateRoute
          path={SETTINGS_ROUTES.timeClock.settings()}
          component={Authorization([
            "time-clock-settings",
            "patients-management",
            "admin",
            "settings",
            "privileges-level-8",
          ])(TimeClockSettings)}
        />
        <PrivateRoute
          path={SETTINGS_ROUTES.ePrescriptionPrescribers()}
          component={Authorization([
            "manage-users",
            "settings",
            "admin",
            "settings",
          ])(Prescribers)}
        />
        <PrivateRoute
          path={SETTINGS_ROUTES.ePrescriptionSupportUsers()}
          component={Authorization([
            "manage-users",
            "settings",
            "admin",
            "settings",
          ])(SupportUsers)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.setupEPrescription()}
          component={Authorization([
            "manage-users",
            "settings",
            "admin",
            "settings",
          ])(Eprescription)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.renewEprescription({
            prescriberId: ":prescriberId",
            planId: ":planId",
          })}
          component={Authorization([
            "manage-users",
            "settings",
            "admin",
            "settings",
          ])(Eprescription)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.addEprescription(":userType")}
          component={Authorization([
            "manage-users",
            "settings",
            "admin",
            "settings",
          ])(Eprescription)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.setupEprescriptionPlan({
            planId: ":planId",
            userId: ":userId?",
          })}
          component={Authorization([
            "manage-users",
            "settings",
            "admin",
            "settings",
          ])(Eprescription)}
        />
        <PrivateRoute
          path={SETTINGS_ROUTES.arInvoices()}
          component={Authorization([
            "superadmin",
            "settings",
            "admin",
            "settings",
            "manage-invoices",
          ])(Invoices)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.subscriptions()}
          component={Authorization([
            "superadmin",
            "settings",
            "admin",
            "settings",
            "manage-subscription",
          ])(Subscription)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.resourceUsage()}
          component={Authorization([
            "superadmin",
            "settings",
            "admin",
            "settings",
            "manage-subscription",
          ])(ResourceUsage)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.manageSubscriptionUpgrade(":featureName?")}
          component={Authorization([
            "access-all",
            "settings",
            "admin",
            "settings",
          ])(SubscriptionAccelerator)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.posReconsiliation()}
          component={Authorization([
            "superadmin",
            "settings",
            "admin",
            "settings",
            "privileges-level-5",
          ])(PosReconciliation)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.posKlarna()}
          component={Authorization([
            "superadmin",
            "settings",
            "admin",
            "settings",
            "privileges-level-6",
          ])(PosKlarna)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.posDashboard()}
          component={Authorization([
            "superadmin",
            "settings",
            "admin",
            "settings",
            "privileges-level-5",
          ])(PosDashboard)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.posDashboardVerification(":type")}
          component={Authorization([
            "superadmin",
            "settings",
            "admin",
            "settings",
            "privileges-level-5",
          ])(PosDashboardVerification)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.posPayments()}
          component={Authorization([
            "superadmin",
            "settings",
            "admin",
            "settings",
            "privileges-level-5",
          ])(PosPayments)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.posPayouts()}
          component={Authorization([
            "superadmin",
            "settings",
            "admin",
            "settings",
            "privileges-level-5",
          ])(PosPayouts)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.posPayout(":id")}
          component={Authorization([
            "superadmin",
            "settings",
            "admin",
            "settings",
            "privileges-level-5",
          ])(PosPayoutsView)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.posTab(":activeTab?")}
          component={Authorization([
            "superadmin",
            "settings",
            "admin",
            "settings",
            "privileges-level-3",
          ])(Pos)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.posOnboarding()}
          component={Authorization([
            "superadmin",
            "settings",
            "admin",
            "settings",
            "privileges-level-3",
          ])(OnboardingClearent)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.posSetup({
            type: ":type",
            clinicId: ":clinicId?",
          })}
          component={Authorization([
            "superadmin",
            "settings",
            "admin",
            "settings",
            "privileges-level-3",
          ])(PosSetup)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.posCardReader({
            type: ":type",
            clinicId: ":clinicId?",
          })}
          component={Authorization([
            "superadmin",
            "settings",
            "admin",
            "settings",
            "privileges-level-3",
          ])(PosCardReader)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.posPaymentSettings()}
          component={Authorization([
            "superadmin",
            "settings",
            "admin",
            "settings",
            "privileges-level-5",
          ])(PosPaymentSettings)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.posDispute(":disputeId")}
          component={Authorization([
            "dispute-management-access",
            "settings",
            "admin",
            "settings",
            "privileges-level-6",
          ])(PosDisputesView)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.posExpressSetup()}
          component={Authorization([
            "superadmin",
            "settings",
            "admin",
            "settings",
            "privileges-level-3",
          ])(PosStripeReturnUrl)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.posDisputeEvidence({
            id: ":disputeId",
            type: ":type",
          })}
          component={Authorization([
            "dispute-management-access",
            "settings",
            "admin",
            "settings",
            "privileges-level-6",
          ])(PosDisputesEvidenceType)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.posDisputesByType(":type")}
          component={Authorization([
            "dispute-management-access",
            "settings",
            "admin",
            "settings",
            "privileges-level-6",
          ])(PosDisputes)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.posDisputes()}
          component={Authorization([
            "dispute-management-access",
            "settings",
            "admin",
            "settings",
            "privileges-level-6",
          ])(PosDisputes)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.posSetupClearent({
            clinicId: ":clinicId?",
            detailType: ":detailType?",
          })}
          component={Authorization([
            "access-all",
            "settings",
            "admin",
            "settings",
          ])(PosClearanceSetup)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.discountPackages()}
          component={Authorization([
            "view-products-inventory",
            "inventory-management",
            "admin",
            "inventory",
            "privileges-level-6",
          ])(DiscountPackages)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.createDiscountPackage()}
          component={Authorization([
            "view-products-inventory",
            "inventory-management",
            "admin",
            "inventory",
            "privileges-level-6",
          ])(CreateEditDiscountPackage)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.editDiscountPackage(":id")}
          component={Authorization([
            "view-products-inventory",
            "inventory-management",
            "admin",
            "inventory",
            "privileges-level-6",
          ])(CreateEditDiscountPackage)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.discoutGroups()}
          component={Authorization([
            "view-products-inventory",
            "inventory-management",
            "admin",
            "inventory",
            "privileges-level-6",
          ])(DiscountGroups)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.createDiscountGroup()}
          component={Authorization([
            "view-products-inventory",
            "inventory-management",
            "admin",
            "inventory",
            "privileges-level-6",
          ])(CreateEditDiscountGroups)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.editDiscountGroup(":id")}
          component={Authorization([
            "view-products-inventory",
            "inventory-management",
            "admin",
            "inventory",
            "privileges-level-6",
          ])(CreateEditDiscountGroups)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.giftCards()}
          component={Authorization([
            "view-products-inventory",
            "inventory-management",
            "admin",
            "inventory",
            "privileges-level-6",
          ])(EGiftCards)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.createGiftCard()}
          component={Authorization([
            "view-products-inventory",
            "inventory-management",
            "admin",
            "inventory",
            "privileges-level-6",
          ])(CreateEditEGiftCards)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.editGiftCard(":id")}
          component={Authorization([
            "view-products-inventory",
            "inventory-management",
            "admin",
            "inventory",
            "privileges-level-6",
          ])(CreateEditEGiftCards)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.posQuickButtons()}
          component={Authorization([
            "view-products-inventory",
            "inventory-management",
            "admin",
            "inventory",
            "privileges-level-6",
          ])(PosQuickButton)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.createPosQuickButton()}
          component={Authorization([
            "view-products-inventory",
            "inventory-management",
            "admin",
            "inventory",
            "privileges-level-6",
          ])(AddEditposQuickButton)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.editPosQuickButton(":id")}
          component={Authorization([
            "view-products-inventory",
            "inventory-management",
            "admin",
            "inventory",
            "privileges-level-6",
          ])(AddEditposQuickButton)}
        />
        {isShopifyAvailable() && (
          <PrivateRoute
            exact
            path={SETTINGS_ROUTES.integrations.shopify()}
            component={Authorization([
              "superadmin",
              "settings",
              "admin",
              "settings",
              "privileges-level-1_5",
            ])(Shopify)}
          />
        )}
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.integrations.touchMd()}
          component={Authorization([
            "superadmin",
            "settings",
            "admin",
            "settings",
            "privileges-level-1_5",
          ])(IntegrationTouchMD)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.integrations.zoho()}
          component={Authorization([
            "superadmin",
            "settings",
            "admin",
            "settings",
            "privileges-level-1_5",
          ])(IntegrationZoho)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.integrations.hubSpot()}
          component={Authorization([
            "superadmin",
            "settings",
            "admin",
            "settings",
            "privileges-level-1_5",
          ])(IntegrationHubspot)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.integrations.mailChimp()}
          component={Authorization([
            "superadmin",
            "settings",
            "admin",
            "settings",
            "privileges-level-1_5",
          ])(IntegrationMailchimp)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.integrations.activeCampaign()}
          component={Authorization([
            "superadmin",
            "settings",
            "admin",
            "settings",
            "privileges-level-1_5",
          ])(IntegrationActiveCampaign)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.integrations.constantContact()}
          component={Authorization([
            "superadmin",
            "settings",
            "admin",
            "settings",
            "privileges-level-1_5",
          ])(IntegrationConstantContact)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.integrations.integrationReturnUrl()}
          component={Authorization([
            "superadmin",
            "settings",
            "admin",
            "settings",
            "privileges-level-1_5",
          ])(IntegrationReturnUrl)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.integrations.zapier()}
          component={Authorization([
            "superadmin",
            "settings",
            "admin",
            "settings",
            "privileges-level-1_5",
          ])(ZapierIntegration)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.integrations.cherry()}
          component={Authorization([
            "superadmin",
            "settings",
            "admin",
            "settings",
            "privileges-level-1_5",
          ])(CherryIntegration)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.integrations.aspire()}
          component={Authorization([
            "superadmin",
            "settings",
            "admin",
            "settings",
            "privileges-level-1_5",
          ])(AspireIntegration)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.integrations.leadsApi()}
          component={Authorization([
            "superadmin",
            "settings",
            "admin",
            "settings",
            "privileges-level-1_5",
          ])(LeadsApiIntegration)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.integrations.evolus()}
          restrictCondition={!getIsEvolusEnabled()}
          component={Authorization([
            "superadmin",
            "settings",
            "admin",
            "settings",
            "privileges-level-1_5",
          ])(EvolusIntegration)}
        />
        <PrivateRoute
          exact
          path={MD_ROOM_ROUTES.root(":type")}
          component={Authorization(["md", "settings", "admin", "rooms"])(
            MDRoom,
          )}
        />
        <PrivateRoute
          exact
          path={PROVIDER_ROOM_ROUTES.root(":type")}
          component={Authorization(["provider", "settings", "admin", "rooms"])(
            ProviderRoom,
          )}
        />

        <PrivateRoute
          exact
          path={MD_ROOM_ROUTES.consent({ id: ":id", type: ":type" })}
          component={Authorization(["md", "settings", "admin", "global"])(
            ProcedureConsentsDetail,
          )}
        />
        <PrivateRoute
          exact
          path={CLIENTS_ROUTES.consentByType({
            clientId: ":clientID",
            id: ":id",
            type: ":type",
          })}
          component={Authorization([
            "manage-procedures",
            "patients-management",
            "admin",
            "global",
          ])(ProcedureConsentsDetail)}
        />
        <PrivateRoute
          exact
          path={CLIENTS_ROUTES.appointmentConsent({
            clientId: ":clientID",
            type: ":type",
            appointmentId: ":appointmentID",
            consentId: ":consentID",
          })}
          component={Authorization([
            "manage-procedures",
            "patients-management",
            "admin",
            "global",
          ])(DocumentConsents)}
        />
        <PrivateRoute
          exact
          path={CLIENTS_ROUTES.procedureQuestionnaire({
            clientId: ":clientID",
            id: ":id",
            type: ":type",
          })}
          component={Authorization([
            "manage-procedures",
            "patients-management",
            "admin",
            "global",
          ])(ProcedureQuestionnaireDetail)}
        />
        <PrivateRoute
          exact
          path={CLIENTS_ROUTES.procedureQuestionnaireService({
            clientId: ":clientID",
            id: ":id",
            type: ":type",
            serviceId: ":serviceId",
          })}
          component={Authorization([
            "manage-procedures",
            "patients-management",
            "admin",
            "global",
          ])(ProcedureQuestionnaireDetail)}
        />
        <PrivateRoute
          exact
          path={CLIENTS_ROUTES.consent(":id")}
          component={Authorization([
            "manage-procedures",
            "patients-management",
            "admin",
            "clients",
          ])(ProviderConsents)}
        />
        <PrivateRoute
          exact
          path={MD_ROOM_ROUTES.procedure({ id: ":id", type: ":type" })}
          component={Authorization(["md", "settings", "admin", "procedure"])(
            ProcedureDetail,
          )}
        />
        <PrivateRoute
          exact
          path={MD_ROOM_ROUTES.questionnaire({
            id: ":id",
            type: ":type",
          })}
          component={Authorization(["md", "settings", "admin", "global"])(
            ProcedureQuestionnaireDetail,
          )}
        />
        <PrivateRoute
          exact
          path={PROVIDER_ROOM_ROUTES.procedureConsents({
            id: ":id",
            type: ":type",
          })}
          component={Authorization(["provider", "settings", "admin", "global"])(
            ProcedureConsentsDetail,
          )}
        />
        <PrivateRoute
          exact
          path={PROVIDER_ROOM_ROUTES.procedureQuestionnaire({
            id: ":id",
            type: "pending",
          })}
          component={Authorization(["provider", "settings", "admin", "global"])(
            ProviderQuestionnaires,
          )}
        />
        <PrivateRoute
          exact
          path={PROVIDER_ROOM_ROUTES.procedureQuestionnaire({
            id: ":id",
            type: "signed",
          })}
          component={Authorization(["provider", "settings", "admin", "global"])(
            ProcedureQuestionnaireDetail,
          )}
        />
        <PrivateRoute
          exact
          path={PROVIDER_ROOM_ROUTES.sentToMdQuestionnaire(":id")}
          component={Authorization(["provider", "settings", "admin", "global"])(
            ProcedureQuestionnaireDetail,
          )}
        />
        <PrivateRoute
          exact
          path={PROVIDER_ROOM_ROUTES.procedureQuestionnaire({
            id: ":id",
            type: "signed-by-md",
          })}
          component={Authorization(["provider", "settings", "admin", "global"])(
            ProcedureQuestionnaireDetail,
          )}
        />
        <PrivateRoute
          exact
          path={PROVIDER_ROOM_ROUTES.procedureDetail({
            id: ":id",
            type: ":type",
          })}
          component={Authorization([
            "provider",
            "settings",
            "admin",
            "procedure",
          ])(ProcedureDetail)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.procedure({ id: ":id", type: ":type" })}
          component={Authorization([
            "manage-procedures",
            "patients-management",
            "admin",
            "procedure",
          ])(ProcedureDetail)}
        />
        <PrivateRoute
          exact
          path={ROUTES.procedure({
            actionType: ":actionType",
            resourceType: ":resourceType",
            procedureId: ":procedureID?",
            clientId: ":clientID",
            type: ":type",
          })}
          component={Authorization([
            "manage-procedures",
            "patients-management",
            "admin",
            "procedure",
          ])(Procedure)}
        />
        <PrivateRoute
          exact
          path={ROUTES.procedureHealthDetail({
            actionType: ":actionType",
            procedureId: ":procedureID",
            clientId: ":clientID?",
            type: ":type",
          })}
          component={Authorization([
            "manage-procedures",
            "patients-management",
            "admin",
            "procedure",
          ])(ProcedureHealthDetail)}
        />
        <PrivateRoute
          exact
          path={ROUTES.procedureHealth({
            actionType: ":actionType",
            resourceType: ":resourceType",
            procedureId: ":procedureID?",
            clientId: ":clientID",
            type: ":type",
            isPrescribed: ":isPrescribed",
          })}
          component={Authorization([
            "manage-procedures",
            "patients-management",
            "admin",
            "procedure",
          ])(ProcedureHealth)}
        />
        <PrivateRoute
          exact
          path={CLIENTS_ROUTES.addHealthProcedure({
            clientId: ":clientID",
            type: ":type",
            isPrescribed: ":isPrescribed",
          })}
          component={Authorization([
            "manage-procedures",
            "patients-management",
            "admin",
            "procedure",
          ])(ProcedureHealth)}
        />
        <PrivateRoute
          exact
          path={CLIENTS_ROUTES.editProcedureHealth({
            procedureId: ":procedureID",
            clientId: ":clientID",
            serviceId: ":serviceID",
            type: ":type",
            questionnaireId: ":questionnaireID",
          })}
          component={Authorization([
            "manage-procedures",
            "patients-management",
            "admin",
            "procedure",
          ])(EditProcedureHealth)}
        />
        <PrivateRoute
          exact
          path={ROUTES.procedureQuestionnaireList({
            actionType: ":actionType",
            procedureId: ":procedureID",
            clientId: ":clientID?",
            appointmentId: ":appointmentID?",
            serviceId: ":serviceID?",
            type: ":type",
          })}
          component={Authorization([
            "manage-procedures",
            "patients-management",
            "admin",
            "procedure",
          ])(ProcedureQuestionnaireList)}
        />
        <PrivateRoute
          exact
          path={CLIENTS_ROUTES.procedureConsentDetail({
            clientId: ":clientID",
            id: ":id",
            type: ":type",
            consentId: ":consentID",
          })}
          component={Authorization([
            "manage-procedures",
            "patients-management",
            "admin",
            "global",
          ])(ProcedureConsentsDetail)}
        />
        <PrivateRoute
          exact
          path={ROUTES.appointmentQuestionnaireList({
            actionType: ":actionType",
            procedureId: ":procedureID",
            clientId: ":clientID?",
            appointmentId: ":appointmentID?",
            serviceId: ":serviceID?",
            type: ":type",
          })}
          component={Authorization([
            "manage-procedures",
            "patients-management",
            "admin",
            "procedure",
          ])(ProcedureQuestionnaireList)}
        />
        <PrivateRoute
          exact
          path={ROUTES.roomProcedureNotes({
            roomType: ":roomType",
            procedureId: ":procedureID",
            patientId: ":patientID",
            type: ":type",
            noteId: ":noteID?",
          })}
          component={Authorization([
            "view-procedure-notes",
            "patients-management",
            "admin",
            "procedure",
          ])(ProcedureNotes)}
        />
        <PrivateRoute
          exact
          path={MD_ROOM_ROUTES.notes({
            procedureId: ":procedureID",
            patientId: ":patientID",
            type: ":type",
            noteId: ":noteID?",
          })}
          component={Authorization(["md", "settings", "admin", "procedure"])(
            ProcedureNotes,
          )}
        />
        <PrivateRoute
          exact
          path={PROVIDER_ROOM_ROUTES.procedureNote({
            procedureId: ":procedureID",
            patientId: ":patientID",
            type: ":type",
            noteId: ":noteID?",
          })}
          component={Authorization([
            "provider",
            "settings",
            "admin",
            "procedure",
          ])(ProcedureNotes)}
        />
        <PrivateRoute
          exact
          path={CLIENTS_ROUTES.procedureNotes({
            procedureId: ":procedureID",
            clientId: ":patientID",
            type: ":type",
          })}
          component={Authorization([
            "view-procedure-notes",
            "patients-management",
            "admin",
            "procedure",
          ])(ProcedureNotes)}
        />
        <PrivateRoute
          exact
          path={ROUTES.procedureConsent({
            actionType: ":actionType",
            resourceType: ":resourceType",
            procedureId: ":procedureID?",
            clientId: ":clientID",
            type: ":type",
          })}
          component={Authorization([
            "access-all",
            "patients-management",
            "admin",
            "procedure",
          ])(ProcedureConsents)}
        />
        <PrivateRoute
          exact
          path={ROUTES.procedurePrescriptionDetail({
            actionType: ":actionType",
            procedureId: ":procedureID",
            clientId: ":clientID?",
            type: ":type",
          })}
          component={Authorization([
            "access-all",
            "patients-management",
            "admin",
            "procedure",
          ])(ProcedurePrescriptionDetail)}
        />
        <PrivateRoute
          exact
          path={CLIENTS_ROUTES.customerNotes({
            actionType: ":actionType",
            clientId: ":patientID",
            type: ":type",
          })}
          component={Authorization([
            "view-customer-notes",
            "patients-management",
            "admin",
            "procedure",
          ])(ClientNotes)}
        />
        <PrivateRoute
          exact
          path={ROUTES.invoice({
            actionType: ":actionType",
            invoiceId: ":invoiceID",
            clientId: ":clientID",
          })}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "sales",
          ])(InvoiceDetails)}
        />
        <PrivateRoute
          exact
          path={ROUTES.invoiceByType({
            actionType: ":actionType",
            invoiceId: ":invoiceID",
            clientId: ":clientID",
            type: ":type",
          })}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "sales",
          ])(InvoiceDetails)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.invoice({
            id: ":invoiceId",
            type: ":type",
          })}
          component={Authorization([
            "view-sales-invoices",
            "sales",
            "admin",
            "procedure",
            "manage-invoices",
          ])(InvoiceView)}
        />
        <PrivateRoute
          exact
          path={CLIENTS_ROUTES.treatmentMarkings({
            procedureId: ":procedureID",
            clientId: ":clientID",
            type: ":type",
          })}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "clients",
          ])(TreatmentMarkings)}
        />
        <PrivateRoute
          exact
          path={CLIENTS_ROUTES.traceabilityInfo({
            actionType: ":actionType",
            procedureId: ":procedureID",
            clientId: ":clientID",
            type: ":type",
            subType: ":subType?",
          })}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "global",
          ])(TraceabilityInfo)}
        />
        <PrivateRoute
          exact
          path={CLIENTS_ROUTES.upcomingAppointments({
            actionType: ":actionType",
            id: ":clientID",
            type: ":type",
          })}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "clients",
          ])(UpcomingAppointments)}
        />
        <PrivateRoute
          exact
          path={ROUTES.medicalHistory({
            actionType: ":actionType",
            clientId: ":clientID",
            type: ":type",
          })}
          component={Authorization([
            "manage-medical-history",
            "patients-management",
            "admin",
            "clients",
          ])(MedicalHistory)}
        />
        <PrivateRoute
          exact
          path={ROUTES.paymentHistory({
            actionType: ":actionType",
            clientId: ":clientID",
            type: ":type",
            tab: ":tabType",
          })}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "clients",
          ])(PaymentHistory)}
        />
        <PrivateRoute
          path={SETTINGS_ROUTES.dotPhrases()}
          exact
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "settings",
          ])(DotPhrases)}
        />
        <PrivateRoute
          path={SETTINGS_ROUTES.dotPhrase({
            actionType: ":actionType",
            id: ":id?",
          })}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "settings",
          ])(AddUpdateDotPhrase)}
        />
        <PrivateRoute
          path={ROUTES.afterPhotos({
            actionType: ":actionType",
            procedureId: ":procedureID",
            clientId: ":clientID",
            action: ":action",
          })}
          exact
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "settings",
          ])(AfterPhotos)}
        />
        <PrivateRoute
          exact
          path={ROUTES.waitlist()}
          component={Authorization([
            "create-appointment",
            "appointments",
            "admin",
            "appointments",
          ])(Waitlist)}
        />
        <BiPrivateRoute
          path={BI_ROUTES.inventoryReports()}
          exact
          component={Authorization([
            "view-sales-report",
            "business-insights",
            "admin",
            "business_insights",
            "privileges-level-2",
          ])(InventoryInsights)}
        />
        <BiPrivateRoute
          path={BI_ROUTES.clientsReports()}
          exact
          component={Authorization([
            "view-sales-report",
            "business-insights",
            "admin",
            "business_insights",
            "privileges-level-2",
          ])(PatientsInsights)}
        />
        <BiPrivateRoute
          path={BI_ROUTES.sales.root()}
          exact
          component={Authorization([
            "business-insight-sales",
            "business-insights",
            "admin",
            "business_insights",
            "privileges-level-10",
          ])(SalesInsights)}
        />
        <BiPrivateRoute
          path={BI_ROUTES.appointmentsReports()}
          exact
          component={Authorization([
            "view-sales-report",
            "business-insights",
            "admin",
            "business_insights",
            "privileges-level-2",
          ])(AppointmentsInsights)}
        />
        <BiPrivateRoute
          path={BI_ROUTES.qualiphyPaidExamsReport()}
          exact
          component={Authorization([
            "view-sales-report",
            "business-insights",
            "admin",
            "business_insights",
            "privileges-level-2",
          ])(QualiphyPaidExamsReport)}
        />
        <BiPrivateRoute
          path={BI_ROUTES.noClinicPatientsReport()}
          exact
          component={Authorization([
            "view-sales-report",
            "business-insights",
            "admin",
            "business_insights",
            "privileges-level-2",
          ])(NoClinicPatientsReport)}
        />
        <BiPrivateRoute
          path={BI_ROUTES.retentionRateReport()}
          exact
          component={Authorization([
            "view-sales-report",
            "business-insights",
            "admin",
            "business_insights",
            "privileges-level-2",
          ])(RetentionRateReport)}
        />
        <BiPrivateRoute
          path={BI_ROUTES.retentionRateDetailsReport()}
          exact
          component={Authorization([
            "view-sales-report",
            "business-insights",
            "admin",
            "business_insights",
            "privileges-level-2",
          ])(RetentionRateDetailsReport)}
        />
        <BiPrivateRoute
          path={BI_ROUTES.clientsReport(":reportType")}
          exact
          component={Authorization([
            "view-sales-report",
            "business-insights",
            "admin",
            "business_insights",
            "privileges-level-2",
          ])(PatientsInsightReport)}
        />
        <BiPrivateRoute
          path={BI_ROUTES.clientsReportByInsight({
            type: ":reportType",
            id: ":reportID?",
            insightId: ":insightID?",
          })}
          exact
          component={Authorization([
            "view-sales-report",
            "business-insights",
            "admin",
            "business_insights",
            "privileges-level-2",
          ])(PatientsInsightReport)}
        />
        <BiPrivateRoute
          path={BI_ROUTES.appointmentReportByType(":reportType")}
          exact
          component={Authorization([
            "view-sales-report",
            "business-insights",
            "admin",
            "business_insights",
            "privileges-level-2",
          ])(AppointmentsInsightReport)}
        />
        <BiPrivateRoute
          path={BI_ROUTES.inventoryReportByType(":reportType")}
          exact
          component={Authorization([
            "view-sales-report",
            "business-insights",
            "admin",
            "business_insights",
            "privileges-level-2",
          ])(InventoryInsightReport)}
        />
        <BiPrivateRoute
          path={BI_ROUTES.inventoryReportByInsight({
            type: ":reportType",
            id: ":reportID?",
            insightId: ":insightID?",
          })}
          exact
          component={Authorization([
            "view-sales-report",
            "business-insights",
            "admin",
            "business_insights",
            "privileges-level-2",
          ])(InventoryInsightReport)}
        />
        <BiPrivateRoute
          path={BI_ROUTES.sample()}
          exact
          component={Authorization([
            "view-sales-report",
            "business-insights",
            "admin",
            "business_insights",
            "privileges-level-2",
          ])(SampleInsights)}
        />
        <BiPrivateRoute
          path={BI_ROUTES.appointmentReportByInsight({
            type: ":reportType",
            id: ":reportID?",
            insightId: ":insightID?",
          })}
          exact
          component={Authorization([
            "view-sales-report",
            "business-insights",
            "admin",
            "business_insights",
            "privileges-level-2",
          ])(AppointmentsInsightReport)}
        />
        <BiPrivateRoute
          path={BI_ROUTES.sales.expiredLiabilityReport()}
          exact
          component={Authorization([
            "business-insight-sales",
            "business-insights",
            "admin",
            "business_insights",
            "privileges-level-10",
          ])(ExpiredLiabilityReport)}
        />
        <BiPrivateRoute
          path={"/refactor-business-insight/sales/short_term_liability/expired"}
          exact
          component={Authorization([
            "business-insight-sales",
            "business-insights",
            "admin",
            "business_insights",
            "privileges-level-10",
          ])(NewExpiredLiabilityReport)}
        />
        <BiPrivateRoute
          path={BI_ROUTES.sales.expiringLiabilityReport()}
          exact
          component={Authorization([
            "business-insight-sales",
            "business-insights",
            "admin",
            "business_insights",
            "privileges-level-10",
          ])(ExpiringLiabilityReport)}
        />
        <BiPrivateRoute
          path={BI_ROUTES.giftCardHistory(":id")}
          exact
          component={Authorization([
            "business-insight-sales",
            "business-insights",
            "admin",
            "business_insights",
            "privileges-level-10",
          ])(BiGiftCardHistory)}
        />
        <BiPrivateRoute
          path={
            "/refactor-business-insight/sales/short_term_liability/expiring"
          }
          exact
          component={Authorization([
            "business-insight-sales",
            "business-insights",
            "admin",
            "business_insights",
            "privileges-level-10",
          ])(NewExpiringLiabilityReport)}
        />
        <BiPrivateRoute
          path={BI_ROUTES.sales.reportByType(":reportType")}
          exact
          component={Authorization([
            "business-insight-sales",
            "business-insights",
            "admin",
            "business_insights",
            "privileges-level-10",
          ])(SalesInsightsReport)}
        />
        <BiPrivateRoute
          path={BI_ROUTES.npsReports()}
          exact
          component={Authorization([
            "view-sales-report",
            "business-insights",
            "admin",
            "business_insights",
            "privileges-level-2",
          ])(ReputationInsights)}
        />
        <BiPrivateRoute
          path={BI_ROUTES.npsReport(":reportType")}
          exact
          component={Authorization([
            "view-sales-report",
            "business-insights",
            "admin",
            "business_insights",
            "privileges-level-2",
          ])(ReputationInsightReport)}
        />
        <BiPrivateRoute
          path={BI_ROUTES.npsReportDetails({
            type: ":reportType",
            id: ":reportID?",
            insightId: ":insightID?",
          })}
          exact
          component={Authorization([
            "view-sales-report",
            "business-insights",
            "admin",
            "business_insights",
            "privileges-level-2",
          ])(ReputationInsightReport)}
        />
        <BiPrivateRoute
          path={BI_ROUTES.staffReports()}
          exact
          component={Authorization([
            "view-sales-report",
            "business-insights",
            "admin",
            "business_insights",
            "privileges-level-2",
          ])(StaffInsights)}
        />
        <BiPrivateRoute
          path={BI_ROUTES.staffReportByType(":reportType")}
          exact
          component={Authorization([
            "view-sales-report",
            "business-insights",
            "admin",
            "business_insights",
            "privileges-level-2",
          ])(StaffInsightReport)}
        />
        <BiPrivateRoute
          path={BI_ROUTES.staffReportByInsight({
            type: ":reportType",
            id: ":reportID?",
            insightId: ":insightID?",
          })}
          exact
          component={Authorization([
            "view-sales-report",
            "business-insights",
            "admin",
            "business_insights",
            "privileges-level-2",
          ])(StaffInsightReport)}
        />
        <BiPrivateRoute
          exact
          path={BI_ROUTES.surveys()}
          component={Authorization([
            "view-sales-report",
            "business-insights",
            "admin",
            "business_insights",
            "privileges-level-2",
          ])(BiViewAllSurveys)}
        />
        <BiPrivateRoute
          exact
          path={BI_ROUTES.survey({ surveyId: ":surveyID", id: ":id" })}
          component={Authorization([
            "view-sales-report",
            "business-insights",
            "admin",
            "business_insights",
            "privileges-level-2",
            "privileges-level-2",
          ])(BiSurveyData)}
        />
        <BiPrivateRoute
          path={BI_ROUTES.shareClients()}
          exact
          component={Authorization([
            "view-sales-report",
            "business-insights",
            "admin",
            "business_insights",
            "privileges-level-2",
          ])(ShareReportReturnUrl)}
        />
        <BiPrivateRoute
          path={BI_ROUTES.timeCardReports()}
          exact
          component={Authorization([
            "view-time-card",
            "business-insights",
            "admin",
            "business_insights",
            "privileges-level-9",
          ])(TimeCardInsights)}
        />
        <BiPrivateRoute
          path={BI_ROUTES.chartAudit()}
          exact
          component={Authorization([
            "view-chart-audit",
            "business-insights",
            "admin",
            "business_insights",
            "privileges-level-14",
          ])(ChartAudit)}
        />
        <PrivateRoute
          path={ROUTES.baVideos({
            actionType: ":actionType",
            procedureId: ":procedureID",
            clientId: ":clientID",
            action: ":action",
          })}
          exact
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "settings",
          ])(BAVideos)}
        />
        <PrivateRoute
          path={APPOINTMENT_ROUTES.meeting()}
          exact
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "settings",
          ])(Meeting)}
        />
        <PrivateRoute
          exact
          path={ROUTES.procedurePrescription({
            actionType: ":actionType",
            procedureId: ":procedureID",
            patientId: ":patientID",
            index: ":objIndex",
            viewOnly: ":viewOnly",
            type: ":type",
          })}
          component={Authorization([
            "view-patients",
            "patients-management",
            "admin",
            "settings",
          ])(ProcedurePrescriptions)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.inbox()}
          component={Authorization(
            ["access-all", "appointments", "admin", "settings"],
            {
              mainAdminOnly: true,
            },
          )(SettingsInbox)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.deletedPatients()}
          component={Authorization([
            "access-all",
            "appointments",
            "admin",
            "settings",
          ])(SettingsDeletedPatients)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.deletedNotes()}
          component={Authorization([
            "access-all",
            "appointments",
            "admin",
            "settings",
          ])(SettingsDeletedNotes)}
        />
        <PrivateRoute
          exact
          path={ROUTES.nonDuplicateProfiles()}
          component={Authorization([
            "access-all",
            "appointments",
            "admin",
            "settings",
          ])(NonDupPatients)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.deletedSurveys()}
          component={Authorization([
            "access-all",
            "appointments",
            "admin",
            "settings",
          ])(SettingsDeletedSurveys)}
        />
        <PrivateRoute
          exact
          path={SALES_ROUTES.checkoutInvoice(":id", ":type")}
          component={Authorization([
            "virtual-terminal",
            "sales",
            "admin",
            "sales",
          ])(SalesCheckoutInvoice)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.integrations.qualiphy()}
          component={Authorization([
            "superadmin",
            "settings",
            "admin",
            "settings",
            "privileges-level-1_5",
          ])(Qualiphy)}
        />
        {getIsEfaxIntegrationAccessible() && (
          <PrivateRoute
            exact
            path={ROUTES.settings.integrations.efax()}
            component={Authorization([
              "superadmin",
              "settings",
              "admin",
              "settings",
              "privileges-level-1_5",
            ])(Efax)}
          />
        )}
        <PrivateRoute
          path={ROUTES.settings.efaxTemplates()}
          exact
          component={Authorization([
            "access-all",
            "settings",
            "admin",
            "settings",
          ])(EfaxTemplates)}
        />
        <PrivateRoute
          path={ROUTES.settings.createEfaxTemplate()}
          exact
          component={Authorization([
            "access-all",
            "settings",
            "admin",
            "settings",
          ])(EfaxTemplateCreateEdit)}
        />
        <PrivateRoute
          path={ROUTES.settings.createEfaxFavorite()}
          exact
          component={Authorization([
            "access-all",
            "settings",
            "admin",
            "settings",
          ])(EfaxFavoriteCreateEdit)}
        />
        <PrivateRoute
          path={ROUTES.settings.editEfaxTemplate(":id")}
          exact
          component={Authorization([
            "access-all",
            "settings",
            "admin",
            "settings",
          ])(EfaxTemplateCreateEdit)}
        />
        <PrivateRoute
          path={ROUTES.settings.editEfaxFavorite(":id")}
          exact
          component={Authorization([
            "access-all",
            "settings",
            "admin",
            "settings",
          ])(EfaxFavoriteCreateEdit)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.integrations.quickBooks()}
          component={Authorization([
            "superadmin",
            "settings",
            "admin",
            "settings",
            "privileges-level-1_5",
          ])(QuickBooks)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.integrations.repeatMd()}
          component={Authorization([
            "superadmin",
            "settings",
            "admin",
            "settings",
            "privileges-level-1_5",
          ])(RepeatMd)}
        />
        <PrivateRoute
          exact
          path={SETTINGS_ROUTES.betaProgram()}
          component={Authorization([
            "superadmin",
            "settings",
            "admin",
            "settings",
            "privileges-level-1_5",
          ])(BetaProgram)}
        />
        <BiPrivateRoute
          path={BI_ROUTES.qualiphyPaidExamsReport()}
          exact
          component={Authorization([
            "view-sales-report",
            "business-insights",
            "admin",
            "business_insights",
            "privileges-level-2",
          ])(QualiphyPaidExamsReport)}
        />
        <BiPrivateRoute
          path="/refactor-business-insight/:type/:report?/:subReport?"
          exact
          component={Authorization([
            "business-insight-sales",
            "business-insights",
            "admin",
            "business_insights",
            "privileges-level-10",
          ])(NewBusinessInsights)}
        />

        <Redirect exact from="/*" to={ROUTES.login()} />
      </Switch>
    </ARLayout>
  </ErrorBoundary>
);
